import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PageTransition from 'gatsby-plugin-page-transitions';

import Footer from '../components/common/footer';
import { AppearText, AppearImage } from '../components/common/appear';
import P from '../components/common/p';
import TagButton from '../components/tagButton';

const Page = styled.div`
  min-height: 100vh;
  background: #FFF;
  padding-top: 241px;
  max-width: 1241px;
  margin: 0 auto;
  padding: 0 135px;
  padding-top: 241px;

  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
    padding: 0 104px;
    padding-top: 241px;
  }
  @media (max-width: 1200px) {
    padding: 0 57px;
    padding-top: 241px;
    min-height: auto;
  }

  @media (max-width: 600px) {
      padding: 0 15px;
      padding-top: 180px;
  }
`;

const FooterWrapper = styled.div`
    margin-top: 140px;
    padding-bottom: 60px;
    width: 100%;
    @media (max-width: 600px) {
        margin-top: 60px;
    }
`;

const H1 = styled.h1`
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 92px;
    line-height: 80px;
    color: #0064BA;
    /* or 87% */
    @media (max-width: 1200px) {
        font-style: normal;
        font-weight: bold;
        font-size: 92px;
        line-height: 80px;
    }

    @media (max-width: 600px) {
        font-style: normal;
        font-weight: bold;
        font-size: 50px;
        line-height: 54px;
    }
`;

const H3 = styled.h3`
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #0064BA;
    margin-top: 61px;
    margin-bottom: 40px;
    @media (max-width: 600px) {
        font-size: 28px;
        line-height: 38px;
        margin-top: 41px;
        margin-bottom: 22px;
    }
`;

const PdfAboutDownload = styled.div`
    margin-top: 93px;
    margin-bottom: 148px;
    width: 100%;
    max-width: 750px;
    @media (max-width: 600px) {
        display: none;
    }
`;


const PdfAboutDownloadMobile = styled.div`
    margin-top: 53px;
    margin-bottom: 68px;
    width: 100%;
    max-width: 750px;
    display: none;
    @media (max-width: 600px) {
        display: block;
    }
`;

const RowPartners = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const ImgPartners = styled.img`
    max-width: 320px;
    height: auto;
`;
const ImgPartnersParent = styled.div`
    margin-bottom: 30px;
    @media (max-width: 600px) {
        width: 100%;
        text-align: center;
    }
`;
const exampleText = `
    ក្រុមហ៊ុន ប៊្លូ អូសិន ជាដៃគូផ្ដល់ដំណោះស្រាយដ៏ល្អប្រសើរបំផុតសម្រាប់លោកអ្នក។
ការឈានចូលនៅក្នុងវិស័យធានារ៉ាប់រងគឺជាបញ្ហាដ៏ធំមួយ ហេតុនេះហើយទើបអតិថិជន និងភាគីពាក់ព័ន្ធរបស់យើងត្រូវការជំនួយប្រកបដោយជំនាញវិជ្ជាជីវៈ។ យើងតែងតែយកចិត្តទុក ដាក់ខ្ពស់ចំពោះតម្រូវការ និងទ្រព្យសម្បត្តិរបស់អតិថិជនទាំងអស់ ដោយផ្ដល់ជូននូវផែនការសកម្មភាពដ៏ល្អប្រសើរបំផុតដែលអាចអនុវត្តបានដោយជោគជ័យ។ ដំណោះស្រាយដែលយើងផ្ដល់ជូន និងទីផ្សាររបស់យើងគឺមានប្រសិទ្ធភាព និងសុវត្ថិភាពខ្ពស់បំផុត។ យើងផ្ដល់ជូននូវដំណោះស្រាយចំពោះតម្រូវការគ្រប់ប្រភេទទាំងអស់ រាប់ចាប់ពីការធានារ៉ាប់រងផ្ទាល់ខ្លួន រហូតដល់ការធានារ៉ាប់រងពាណិជ្ជកម្ម។ ក្រុមហ៊ុន ប៊្លូ អូសិន គឺជាជម្រើសដ៏ល្អប្រសើរបំផុតសម្រាប់ទំនុកចិត្តរបស់លោកអ្នក។
យើងផ្ដោតការយកចិត្តទុកដាក់ទៅលើការអភិវឌ្ឍផែនការសកម្មភាព ដែលផ្ដល់សេវាកម្មទៅតាមតម្រូវការរបស់អតិថិជននីមួយៗនៅក្នុងវិស័យធានារ៉ាប់រងដែលកំពុងមានការរីកចម្រើនយ៉ាងខ្លាំងក្លានេះ។ គុណតម្លៃ និងបំណងប្រាថ្នារបស់យើង បានធ្វើឱ្យយើងសម្រេចបាននូវការផ្លាស់ប្ដូរគួរឱ្យកត់សម្គាល់ ពោលគឺ យើងបានផ្ដល់ផលិតផល និងសេវាកម្មមានភាពច្នៃប្រឌិតខ្ពស់ ដោយកិច្ចការរបស់យើងផ្ដោតជាការសំខាន់ទៅលើការបំពេញតាមតម្រូវការរបស់អតិថិជន។ តាមរយៈ ភាពច្នៃប្រឌិត និងបច្ចេកវិទ្យា យើងប្រើប្រាស់ពហុជំនាញរបស់យើងប្រកបដោយប្រសិទ្ធភាពខ្ពស់នៅគ្រប់ទិដ្ឋភាពទាំងអស់នៃសេវាកម្មធានារ៉ាប់រងដែលយើងផ្ដល់ជូន។ តាមរយៈបទពិសោធន៍នាពេលកន្លងមករបស់យើង អតិថិជន និងដៃគូទាំងអស់ប្រាកដជាទទួលបានគំនិតថ្មីៗដែលធ្វើឱ្យ សេវាកម្មធានារ៉ាប់រងកាន់តែមានជម្រើសសម្បូរបែបថែមទៀត។
យើងមិនត្រឹមតែគិតគូរអំពីតម្រូវការជាក់ស្ដែងរបស់អតិថិជនប៉ុណ្ណោះនោះទេ តែថែមទាំងយកចិត្តទុកដាក់ខ្ពស់ចំពោះបំណងប្រាថ្នា ឬគម្រោងនាពេលអនាគតរបស់ពួកគេផងដែរ។
`;

// need to adapt to backend
const AboutPage = ({ data, }) => (
    <PageTransition>
        <>
            <Page>
                <AppearText>
                    <H1>
                        {
                            data.markdownRemark &&
                            (
                                data.markdownRemark.frontmatter.title_kh ? 
                                    data.markdownRemark.frontmatter.title_kh :
                                    data.markdownRemark.frontmatter.title
                            )
                        }
                    </H1>
                </AppearText>
                <AppearText>
                    <P dangerouslySetInnerHTML={{
                        __html: data.markdownRemark &&
                            (
                                data.markdownRemark.frontmatter.paragraph_kh ?
                                    data.markdownRemark.frontmatter.paragraph_kh :
                                    data.markdownRemark.frontmatter.paragraph
                            )
                    }} />
                </AppearText>
                <H3><AppearText>
                    {
                        data.markdownRemark &&
                        (
                            data.markdownRemark.frontmatter.second_title_kh ?
                                data.markdownRemark.frontmatter.second_title_kh :
                                data.markdownRemark.frontmatter.second_title
                        )
                    }    
                </AppearText></H3>
                <P nmt>
                    <AppearText>
                        {
                            data.markdownRemark &&
                            (
                                data.markdownRemark.frontmatter.second_paragraph_kh ?
                                    data.markdownRemark.frontmatter.second_paragraph_kh :
                                    data.markdownRemark.frontmatter.second_paragraph
                            )
                        }
                </AppearText></P>
                
                <H3><AppearText>
                    {
                        data.markdownRemark &&
                        (
                            data.markdownRemark.frontmatter.third_title_kh ?
                                data.markdownRemark.frontmatter.third_title_kh :
                                data.markdownRemark.frontmatter.third_title
                        )
                    }
                </AppearText></H3>
                <P nmt><AppearText>
                    {
                        data.markdownRemark &&
                        (
                            data.markdownRemark.frontmatter.second_paragraph_kh ?
                                data.markdownRemark.frontmatter.second_paragraph_kh :
                                data.markdownRemark.frontmatter.second_paragraph
                        )
                    }
                    </AppearText></P>
                    <AppearImage>
                    <PdfAboutDownload>
                        <TagButton 
                            image={3}
                            boldText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf}
                            normalText=""
                            full
                            onClickHandler={() => {
                                const url = data.markdownRemark && data.markdownRemark.frontmatter.pdf_download;
                                if ( url ) {
                                    let win = window.open(url, '_blank');
                                    win.focus();
                                }
                            }}
                        />
                    </PdfAboutDownload>
                    <PdfAboutDownloadMobile>
                        <TagButton
                            image={3}
                            boldText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf.split(' ')[0]}
                            normalText={data.markdownRemark && data.markdownRemark.frontmatter.text_pdf.split(' ').splice(1).join(' ')}
                            full
                            onClickHandler={() => {
                                const url = data.markdownRemark && data.markdownRemark.frontmatter.pdf_download;
                                if (url) {
                                    let win = window.open(url, '_blank');
                                    win.focus();
                                }
                            }}
                        />
                    </PdfAboutDownloadMobile>
                </AppearImage>
                <H3><AppearText>Our Partners</AppearText></H3>
                <RowPartners>
                    {
                        data.markdownRemark && data.markdownRemark.frontmatter.partners.map((element, index) => {
                            return <ImgPartnersParent>
                                <AppearImage>
                                    <ImgPartners
                                        src={element.logo}
                                        alt={element.alttext}
                                        first={index === 0}
                                        last={index !== 0 && index % 4 === 0}
                                    />
                                </AppearImage>
                            </ImgPartnersParent>
                        })
                    }
                </RowPartners>
                <FooterWrapper >
                    <Footer noPadding />
                </FooterWrapper> 
            </Page>
        </>
    </PageTransition>
)

export default function myAbout(props) {
    return (
        <StaticQuery
            query={graphql`
                query {
                    markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
                        frontmatter {
                            title
                            title_kh
                            paragraph
                            paragraph_kh
                            second_title
                            second_title_kh
                            second_paragraph
                            second_paragraph_kh
                            third_title
                            third_title_kh
                            third_paragraph
                            third_paragraph_kh
                            pdf_download
                            text_pdf
                            partners {
                                logo
                                alttext
                            }
                        }
                    }
                }
            `}
            render={data => <AboutPage data={data} {...props} />}
        />
    )
};
